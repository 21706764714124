.result-name-card {
    color: #e7e9f0;
    background: #282d4f;
    margin: 10px 15px;
    min-width: 75px;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.1);
    transition: background 0.35s;
    display: flex;
    justify-content: center;
}

.card-link {
    text-decoration: none;
}

.result-name-card:hover {
    background: #4a5079;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}

.result-name {
    margin: 0;
    font-weight: 100;
}
