.head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.head-image {
    width: auto;
    transition: max-width 1s, min-width 1s;
}

.head-image-expanded {
    min-width: 230px;
    max-width: 250px;
}

.head-image-contracted {
    max-width: 60px;
    min-width: 40px;
}

.head-text {
    font-family: 'Hachi Maru Pop', cursive;
    margin: 5px 0 15px 0;
    transition: font-size 1s;
}

.head-text-expanded {
    font-size: 36px;
}

.head-text-contracted {
    font-size: 12px;
}
